import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
          <h1 className="text-light main-header">Mark Ringtved</h1>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.facebook.com/mark.ringtved"><img src={navIcon1} alt="Facebook Mark Ringtved Nielsen" /></a>
              <a href="https://www.linkedin.com/in/mark-ringtved-nielsen-511635164/"><img src={navIcon2} alt="Linkedin Mark Ringtve Nielsen" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved / markringtved.dk</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
